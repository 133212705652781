import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TitleBar from '../widgets/TitleBar';
import home from '../../assets/images/homepage.png'

const query = `{
  articlePage (id:"5ejyrDfUbEH1wSq9AJNdlz") {
    featureArticle {
      sys {
        id
      }
      blogTitle
      blogImage {
        url
        title
        description
        width
        height
      }
      blogAuthor
      blogSummary
      postContent
      createDate
      vanityUrl
    }
    articlesCollection {
      items {
        sys {
          id
        }
        blogTitle
        blogImage {
          url
          title
          description
          width
          height
        }
        blogAuthor
        blogSummary
        postContent
        createDate
        vanityUrl
      }
    }
  }
}`

function Posts() {
  const [post, setPost] = useState([])
  const [feature, setFeature] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/boyp2iuzti5x/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer UXYjH6zlFyOT_IEb3XEQg8q1r8hn4zXBlPpdjaUzYN0",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setPost(data.articlePage.articlesCollection.items);
        setFeature(data.articlePage.featureArticle);
        setLoading(false)
      });
  }, []);

  // show a loading screen case the data hasn't arrived yet
  if (loading) {
    return (
      <>
        <section>
          <div className="container flex felx-col mx-auto">
            <div className="py-64 mx-auto text-center">
              <p className='mt-8'>Loading Articles...</p>
            </div>
          </div>
        </section>
      </>
    )
  }


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Platypus Health | Articles</title>
            <meta name="description" content="{singleBlogPost?.summary}" />
						
						<link rel="canonical" href={`https://www.platypus.health/articles`} />

            <meta property="og:title" content= "Platypus Health | Articles" />
            <meta property="og:description" content="Articles and news from Platypus Health" />
            <meta property="og:image" content={`https://www.platypus.health${home}`} />
            <meta property="og:url" content="https://www.platypus.health/articles" />
            <meta property="og:type" content="website" />
        </Helmet>
      </HelmetProvider>

      {/* Title Section */}
      <TitleBar 
        text='Articles'
      />

       {/* Feature Article */}
      <section className='bg-platyGray'>
        <div className="container flex flex-col px-6 pt-16 items-center pb-16 mx-auto md:flex-row md:space-x-16">
          {/* Image */}
          <div className="lg:w-1/2 p-4">
            <Link
             to={`/article/${feature.vanityUrl}`}
             className="hover:grayscale transition duration-700 ease-in-out"
            >
              <img 
                className="" 
                src={feature.blogImage.url} 
                title={feature.blogImage.title}
                alt={feature.blogImage.description}
                height={feature.blogImage.height}
                width={feature.blogImage.width}
                loading="eager" 
              />
            </Link>
          </div>

          {/* Post Content */}
          <div className="lg:w-1/2 p-4">

            {/* Title */}
            <Link
             to={`/article/${feature.vanityUrl}`}
             className=""
            >
              <h2 className="text-4xl pt-3 hover:underline hover:decoration-platyGold">{feature.blogTitle}</h2>
            </Link>

            {/* Summary */}
            <p className='py-3'>{feature.blogSummary}</p>

            {/* Author */}
            <p className="">
              By <span className="font-semibold">{feature.blogAuthor}</span>
            </p>

            {/* Date */}
            <p className='text-sm pb-3'> 
              <span></span>
              
                {new Intl.DateTimeFormat('en', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                }).format(new Date(feature.createDate))}
              
            </p>

            {/* Read more button */}
            <div className="py-4">
              <Link
                to={`/article/${feature.vanityUrl}`}
                className="px-4 py-2 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-platyGray">
                Read More
              </Link>
            </div>
          </div>

        </div>
      </section>



      {/* Articles */}
      <section className='px-6 py-16'>
        <div className="container grid lg:grid-cols-3 mx-auto">

            {post?.map((p) => (

            <div className="p-8" key={p.sys.id}>
              <div className="">
                {/* Image */}
                <Link
                  to={`/article/${p.vanityUrl}`}
                  className="hover:grayscale transition duration-700 ease-in-out"
                >
                  <img 
                    src={p.blogImage.url} 
                    title={p.blogImage.title} 
                    alt={p.blogImage.description} 
                    width={p.blogImage.width}  
                    height={p.blogImage.height}  
                    loading="eager"
                  />
                </Link>
                

                {/* Title */}
                <Link
                  to={`/article/${p.vanityUrl}`}
                  className=""
                >
                  <h3 className="text-4xl mt-5 hover:underline hover:decoration-platyGold">{p.blogTitle}</h3>
                </Link>
                

                {/* Summary */}
                <p className='py-3'>{p.blogSummary}</p>

                {/* Author */}
                <p className="">
                  By <span className="font-semibold">{p.blogAuthor}</span>
                </p>

                {/* Date */}
                <p className='text-sm pb-3'> 
                  <span></span>
                  
                    {new Intl.DateTimeFormat('en', {
                      month: 'long',
                      day: '2-digit',
                      year: 'numeric',
                    }).format(new Date(p.createDate))}
                  
                </p>

              </div>
              <div className="py-4">
                <Link
                  to={`/article/${p.vanityUrl}`}
                  className="px-4 py-2 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-platyGray">
                  Read More
                </Link>
              </div>
            </div>
          ))}
          
        </div>
      </section> 
    </>

  )
}

export default Posts