// Utilities
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PrivacyPage() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Platypus Health | Privacy Policy</title>
        </Helmet>
      </HelmetProvider>
      
      {/*Privacy Page */}
      <section id="about" className="bg-platyGray py-24">
        {/* Container */}
        <div className="container flex flex-col px-6  mx-auto">
          {/* Content */}     
          <div className="font-extralight text-gray-900 space-y-6">
            <h1 className="mb-6 text-4xl font-bold">Privacy</h1>
            <p>This privacy policy ("policy") will help you understand how Populai, Inc. d/b/a Platypus ("us", "we", "our", "platypus") uses and protects the data you provide to us when you visit and use platypus.health ("site"). We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>

            <h2 className='text-2xl font-bold'>What User Data We Collect</h2>
            <p>When you visit the site, we may collect the following data:</p>

            <ul className='font-extralight space-y-3 list-disc mx-4'>
              <li>Your IP address.</li>
              <li>Your contact information and email address.</li>
              <li>Other information such as interests and preferences.</li>
              <li>Data profile regarding your online behavior on our blog.</li>
            </ul>

            <h2 className='text-2xl font-bold'>Why We Collect Data</h2>

            <p>We are collecting your data for several reasons:</p>

            <ul className='font-extralight space-y-3 list-disc mx-4'>
              <li>To better understand your needs.</li>
              <li>To improve our services and products.</li>
              <li>To send you promotional emails containing the information we think you will find interesting.</li>
              <li>To contact you to fill out surveys and participate in other types of market research.</li>
              <li>To customize our site according to your online behavior and personal preferences.</li>
            </ul>

            <h2 className='text-2xl font-bold'>Safeguarding and Securing the Data</h2>

            <p>Platypus is committed to securing your data and keeping it confidential. Platypus has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.</p>

            <h2 className='text-2xl font-bold'>Our Cookie Policy</h2>

            <p>By using our site, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you visit and spend the most time on).</p>

            <p>The data we collect by using cookies is used to customize our blog to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.</p>

            <p>Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.</p>

            <p>If you want to disable cookies, you can do it by accessing the settings of your internet browser. You can visit <a className='text-platyViolet underline' href='https://www.internetcookies.com'>https://www.internetcookies.com</a>, which contains comprehensive information on how to do this on a wide variety of browsers and devices.</p>

            <h2 className='text-2xl font-bold'>Links to Other Websites</h2>

            <p>Our blog contains links that lead to other websites. If you click on these links Platypus is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.</p>

            <h2 className='text-2xl font-bold'>Restricting the Collection of your Personal Data</h2>
            <p>At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:</p>

            <p>When you are filling the forms on the site, make sure to check if there is a box which you can leave unchecked, if you don't want to disclose your personal information. If you have already agreed to share your information with us, feel free to contact us via email and we will be more than happy to change this for you.</p>

            <p>Platypus will not lease, sell or distribute your personal information to any third parties, unless we have your permission. We might do so if the law forces us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy. </p>


           
          </div>         
        </div>

      </section>
    </>
  )
}

export default PrivacyPage