import React from 'react'

import { FaLock, FaChartLine, FaHandshake, FaCheck } from 'react-icons/fa'

function ValueBlock() {
  return (
    <section id="" className="bg-white pt-16 pb-32">
      <div className="container flex flex-col items-center lg:px-16 pb-4 mx-auto lg:flex-row xl:space-x-16">
        {/* Image */}
        <div className="lg:w-1/2 p-2 lg:space-y-5 text-center lg:text-left">
          <p className="text-sm tracking-widest text-platyGrayText uppercase">Our Values</p>
          <h2 className='text-4xl max-w-xl font-bold'>Health equity is an ecosystem problem that requires an ecosystem solution.</h2>
        </div>
        {/* Content */}
        <div className="flex flex-col items-start lg:space-y-5 lg:w-1/2 p-4">
          <p className="pt-4 lg:mt-12 mb-6 font-light text-lg text-center lg:text-left">In a world defined by groundbreaking healthcare technology and data-driven decision-making, achieving health equity is not just a goal - it’s a pressing imperative. </p>
        </div>       
      </div>

      {/* Value Items */}
      <div className="container mx-auto">
        <div className="flex flex-col flex-wrap justify-center lg:flex-nowrap md:flex-row">

          {/* Item 1 */}
          <div className="md:basis-1/3 lg:basis-1/4 border border-solid border-platyDarkGray rounded-xl m-4">
            <div className="p-4">
              <FaLock className='text-platyViolet mx-auto text-6xl py-4'/>
              <h3 className='text-xl font-bold mt-2 mb-2 text-center'>Security</h3>
              <p className='font-light'>Maintain confidentiality and privacy by restricting access to your data and algorithmic assets.</p>
            </div>            
          </div>          

          {/* Item 2 */}
          <div className="md:basis-1/3 lg:basis-1/4 border border-solid border-platyDarkGray rounded-xl m-4">
            <div className="p-4">
              <FaHandshake className='text-platyViolet mx-auto text-6xl py-4'/>
              <h3 className='text-xl font-bold mt-2 mb-2 text-center'>Transparency</h3>
              <p className='font-light'>Build trust in growing organizational reliance on data and analytics in strategic decision making.</p>
            </div>  
          </div>

          {/* Item 3 */}
          <div className="md:basis-1/3 lg:basis-1/4 border border-solid border-platyDarkGray rounded-xl m-4">
            <div className="p-4">
              <FaChartLine className='text-platyViolet mx-auto text-6xl py-4'/>
              <h3 className='text-xl font-bold mt-2 mb-2 text-center'>Quality Outcomes</h3>
              <p className='font-light'>Costs related to health disparities are continuously rising, impacting cost of care margin and medical loss ratios.</p>
            </div>
          </div>

          {/* Item 4 */}
          <div className="md:basis-1/3 lg:basis-1/4 border border-solid border-platyDarkGray rounded-xl m-4">
            <div className="p-4">
              <FaCheck className='text-platyViolet mx-auto text-6xl py-4'/>
              <h3 className='text-xl font-bold mt-2 mb-2 text-center'>Compliance</h3>
              <p className='font-light'>Health equity is now integrated in to more programs, payment models, and compliance certifications.</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default ValueBlock