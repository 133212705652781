import React, {useState} from 'react'
import base from '../../api/base'
import { useForm } from "react-hook-form"
import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function WhitePaperForm() {
  const form = useForm()
  const { register, handleSubmit, formState } = form
  const { errors } = formState
  const [status, setStatus] = useState(false)

  const onSubmit = (e) => {
    const a = document.createElement("a")
    a.href = "https://drive.google.com/file/d/1DVGa-2WNTLpz_rdo7mVEcEyCOc0y933Y/view?usp=drive_link"
    a.download="PlatypusHealth-WhitePaper.pdf"
    a.target = "blank"

    a.click()


    const first_name = e.firstName
    const last_name = e.lastName
    const email = e.email

    base('White Paper Form 2024').create(
      { 
        first_name, 
        last_name, 
        email, 
      },
      function (err, record) {
        if (err) {
          alert('We\'re sorry. Something went wrong, please try again.')
          return;
        }
        setStatus(true)
      }
    );
  };
  return (
    <>
      { status ? (
        <div className='mx-auto text-center space-y-6'>
          <FaCheckCircle className='text-green-700 text-5xl mx-auto' />
          <p>Thank you for your submission. If your download doesn't automatically start click <Link className='underline' target="_blank" download="PlatypusHealth-WhitePaper.pdf" rel="noreferrer" to="https://drive.google.com/file/d/1DVGa-2WNTLpz_rdo7mVEcEyCOc0y933Y/view?usp=drive_link" >Here</Link></p>
        </div>
    ):(

    <form action="submit" onSubmit={handleSubmit(onSubmit)} noValidate>
      <span className='flex flex-col md:flex-row md:space-x-6 space-y-5 md:space-y-0'>

        {/* First Name */}
        <div>
          <label htmlFor='firstName' className='mt-5'>
            <span className='block text-gray-700'>First Name:</span>
          </label>
          <input
            id='firstName'
            type="text"
            autoComplete='given-name' 
            className="mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            placeholder="" 
            {...register("firstName", 
              {required: 'This field is required',
              maxLength: 80
            })}    
          />
          <p className="text-red-700">{errors.firstName?.message}</p>
        </div>
        
        {/* Last Name */}
        <div>
          <label htmlFor='lastName' className='mt-5'>
            <span className='block text-gray-700'>Last Name:</span>
          </label>
          <input 
            id='lastName'
            type="text" 
            autoComplete='family-name' 
            className="mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            placeholder=""
            {...register("lastName", 
              {required: 'This field is required',
              maxLength: 100
            })}  
          />
          <p className="text-red-700">{errors.lastName?.message}</p>
        </div>

      </span>
    
      {/* Email */}
      <div>
        <label htmlFor='email' className='block mt-5'>
          <span className='text-gray-700'>Email:</span>
        </label>
        <input 
          id='email'
          type="email"
          autoComplete='false'
          className="mt-1 w-3/4 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
          placeholder="john@example.com"
          {...register("email", {
            required: 'Please enter valid email address',
            pattern: {value: /^\S+@\S+$/i, message: 'Invalid email format'}
          })} 
        />
         <p className="text-red-700">{errors.email?.message}</p>
      </div>

      {/* Submit */}
      <div className="text-left w-full pt-4 mt-6 space-x-4">
        <button className="p-4 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-white">Download Now</button>
      </div>

    </form>
  )}

  </>    
  )
}

export default WhitePaperForm