import React from 'react'
import PropTypes from 'prop-types'

function TitleBar({text}) {
  return (
    <section id="faq-header" className="bg-title-bar bg-bottom bg-no-repeat bg-cover">
      <div className="container flex flex-col px-6 pt-16 pb-16 mx-auto md:flex-row md:space-x-16">
          <h1 className="mt-4 mb-6 text-3xl font-semibold mx-auto text-white md:text-5xl">{text}</h1>   
      </div>
    </section>
  )
}

TitleBar.defaultProps = {
  text: 'Platypus Health',
}

TitleBar.propTypes = {
  text: PropTypes.string
}

export default TitleBar