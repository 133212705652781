// Utility
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Components
import DownloadWhitePaperCTA from './widgets/DownloadWhitePaperCTA'
import TitleBar from './widgets/TitleBar';
import ValueBlock from './faq-page-sections/ValueBlock';
import Accordian from './faq-page-sections/Accordian';
import FeatureBlock1 from './faq-page-sections/FeatureBlock1';
import LaunchCTA from './faq-page-sections/LaunchCTA';

function FaqPage() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Platypus Health | Frequently Asked Questions</title>
          <meta name="description" content="Frequently asked quesitons at Platypus Health." />
          <link rel="canonical" href={`https://www.platypus.health/frequently-asked-questions`} />
        </Helmet>
      </HelmetProvider>
        
      {/* Title Section */}
      <TitleBar 
        text='Frequently Asked Questions'
      />
      
      {/* Feature Section */}
      <FeatureBlock1 />

      {/* Values Section */}
      <ValueBlock />


      {/* FAQs */}
      <Accordian />

      {/* Whitepaper Section */}
      <DownloadWhitePaperCTA />

      {/* Launch */}
      <LaunchCTA />

    </>
    
  )
}

export default FaqPage