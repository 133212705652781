import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SinglePosts()  {
	const [singleBlogPost, setSingleBlogPost] = useState([]);
	const [loading, setLoading] = useState(true);
	const { vanityUrl } = useParams();
  const navigate = useNavigate();



  const query = `query blogCollectionQuery  {
    blogCollection(where: {vanityUrl: "${vanityUrl}"}) {
      items {
        blogTitle
				blogSummary
        blogImage {
          title
          url
					description
					width
					height
        }
        blogAuthor
        postContent
        createDate
        vanityUrl
      }
    }
  }`

	useEffect(() => {
		window
			.fetch(`https://graphql.contentful.com/content/v1/spaces/boyp2iuzti5x/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					// Authenticate the request
					Authorization: "Bearer UXYjH6zlFyOT_IEb3XEQg8q1r8hn4zXBlPpdjaUzYN0",
				},
				// send the GraphQL query
				body: JSON.stringify({ query }),
			})
			.then((response) => response.json())
			.then(({ data, errors }) => {
				if (errors) {
					console.error(errors);
          navigate("/articles")
				}
        if (data.blogCollection.items.length === 0) {
          navigate("/articles")
        }
				// rerender the entire component with new data
				setSingleBlogPost(data.blogCollection.items[0]);
        setLoading(false)

			});
	}, [query]);

  // show a loading screen case the data hasn't arrived yet
  if (loading) {
    return (
      <>
        <section>
          <div className="container flex felx-col mx-auto">
            <div className="py-64 mx-auto text-center">
              <p className='mt-8'>Loading Articles...</p>
            </div>
          </div>
        </section>
      </>
    )
  }

	return (
		<>
			 <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Platypus Health | {singleBlogPost.blogTitle}</title>
            <meta name="description" content="{singleBlogPost?.summary}" />
						
						<link rel="canonical" href={`https://www.platypus.health/articles/${singleBlogPost.vanityUrl}`} />

            <meta property="og:title" content={`Platypus Health | ${singleBlogPost.blogTitle}`} />
            <meta property="og:description" content={singleBlogPost.blogSummary} />
            <meta property="og:image" content={singleBlogPost?.blogImage?.url} />
            <meta property="og:url" content={`https://www.platypus.health/articles/${singleBlogPost.vanityUrl}`} />
            <meta property="og:type" content="website" />
        </Helmet>
      </HelmetProvider>

			<section>
				<div className="container flex flex-col px-6 md:px-2 py-6 mx-auto md:space-x-16">
					<p className="text-xs">
						<Link to="/articles" className="content-subhead underline">
							Articles
						</Link>{" "}
						<FaArrowRight className="inline" />{" "}
						<span className="content-subhead">
							{singleBlogPost.blogTitle}
						</span>
					</p>
				</div>
			</section>

			<section>
				<div className="container px-6 py-8 mx-auto space-y-6">
					{/* Title */}
					<div className="text-center mx-auto max-w-3xl">
						<h1 className="text-5xl font-bold text-center">
							{singleBlogPost.blogTitle}
						</h1>
					</div>

					{/* Author and Date */}
					<div className="text-center text-sm">
						<p>
							<span className="font-semibold">
								By {singleBlogPost.blogAuthor} |{" "}
							</span>
							<span></span>
							<span>
								{singleBlogPost.createDate === undefined
									? "loading"
									: new Intl.DateTimeFormat("en", {
											month: "long",
											day: "2-digit",
											year: "numeric",
									  }).format(new Date(singleBlogPost.createDate))}
							</span>
						</p>
					</div>

					{/* Image */}
					<div>
						<img
							className="mx-auto"
							src={singleBlogPost?.blogImage?.url}
							title={singleBlogPost?.blogImage?.title}
							alt={`${singleBlogPost?.blogImage?.description}`}
							height={singleBlogPost?.blogImage?.height}
							width={singleBlogPost?.blogImage?.width}
							loading="lazy"
						/>
					</div>

					{/* Post */}
					<div className="max-w-5xl mx-auto py-12">
						{/* <MD source={singleBlogPost?.fields?.blogContent} /> */}
						<ReactMarkdown children={singleBlogPost.postContent} />

						<div className="py-4">
							<Link
								to={`/articles`}
								className="px-4 py-2 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-platyGray">
								Back to Articles
							</Link>
						</div>
					</div>

					
				</div>
			</section>
		</>
	);
}

export default SinglePosts;
