//Utilities
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Images
import WhitePaper from '../assets/images/white-paper.png'

//Components
import WhitePaperForm from './widgets/WhitePaperForm';

function WhitePaperDownload() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Platypus Health | White paper</title>
        </Helmet>
      </HelmetProvider>
  
      {/*Connect */}
      <section id="about" className="bg-platyGray py-24">
        {/*Connect Container */}
        <div className="container flex flex-col px-6 mx-auto md:flex-row md:space-x-16">
            {/* Content */}
            <div className="lg:w-2/3 p-4">
              {/* Text Section */}
              <h1 className="mt-4 mb-6 text-3xl font-semibold mx-auto text-platyGrayText md:text-5xl">Download Our Whitepaper</h1>
              <div className="mt-4 mb-6 text-2xl font-semibold mx-auto text-platyViolet md:text-4xl">Accelerate Equity: The Time-Critical Data Imperative for Healthcare</div>


              <div className="font-extralight text-lg text-gray-900 space-y-6">
                <p>In a world defined by groundbreaking healthcare technology and data-driven decision-making, achieving equity is not just a goal, it’s a pressing imperative. Our free white paper dives deep into the challenges and opportunities presented by this era of unprecedented advancements.</p> 

                <p className='font-bold'>What You’ll Discover:</p>

              <ul className='space-y-6'>
                <li><span className='font-bold'>Executive Order Impact:</span> Explore how President Biden’s October 2023 Executive Order sets new standards for AI safety, privacy protection, and advances in equity.</li>

                <li><span className='font-bold'>Healthcare Inequities Unveiled:</span> Delve into recent attempts to rectify health disparities and understand the pivotal role of data and analytics in quantifying, measuring, and impacting health outcomes and costs.</li>

                <li><span className='font-bold'>Forward-Looking Solutions:</span> Gain insights into strategic solutions tailored for success in the emerging “equity first” environment. Empower yourself with actionable steps that healthcare professionals and stakeholders can take to build a future where health equity is not just an aspiration but a reality.</li>
              </ul>

              <p>The journey toward sustainable health equity starts with knowledge and action. <span className='font-bold'>Download our free white paper today</span> and be a leader in building a healthcare system where everyone has equal access to quality care.</p>
              </div>

              <div className="py-6"><hr /></div>
        
              <div className='max-w-lg'>
                <WhitePaperForm />
              </div>


            </div>

            {/* Photo */}
            <div className="lg:flex flex-col lg:w-1/3 p-2 hidden">
              <div className="flex flex-col space-y-5">
                <img src={WhitePaper} alt="" />
              </div>                             
            </div>
        </div>
      </section>

  
  </>
  )
}

export default WhitePaperDownload