import React from 'react'

function Accordian() {
  return (
    <section className='bg-platyGray' id="faq-accordian">
      {/* Main Container */}

        <div className="container flex flex-col px-6 pt-16 pb-16 mx-auto md:flex-row md:space-x-16">
         
          {/* Accordian Container */}
          <div className="max-w-6xl m-8 mx-auto overflow-hidden">
            <h2 className='mb-6 text-4xl text-center font-bold'>Frequently Asked Questions</h2>

            {/* Tab 1 */}
            <div className="py-1 border-b outline-none group" tabIndex="1">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-600 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="text-xl transition duration-500 group-hover:text-platyBlue">
                  What is health equity?
                </div>

                {/* Arrow */}
                <div className="transition duration-500 group-focus:-rotate-180 ease group-focus:text-platyBlue">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>
              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">

                <p className="p-4 pb-8 max-w-3xl text-platyGrayText font-extralight">
                Health equity is the process of ensuring every individual has equitable access to healthcare resources and services, and is given the best chance for optimal health and healthcare outcomes.
                </p>

              </div>
            </div>

            {/* Tab 2 */}
            <div className="py-1 border-b outline-none group" tabIndex="2">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-600 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="text-xl transition duration-500 group-hover:text-platyBlue">
                  What is Platypus?
                </div>

                {/* Arrow */}
                <div className="transition duration-500 group-focus:-rotate-180 ease group-focus:text-platyBlue">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>
              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                
                  <p className="p-4 pb-8 max-w-3xl text-platyGrayText font-extralight">
                  Platypus is a healthcare tech company dedicated to advancing health equity. We provide a platform where organizations can access tools to understand where disparities are and a network of professionals to systematically overcome them.
                  </p>
                
              </div>
            </div>

            {/* Tab 3 */}
            <div className="py-1 border-b outline-none group" tabIndex="3">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-600 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className=" text-xl transition duration-500 group-hover:text-platyBlue">
                  Is Platypus the right partner for my organization?
                </div>

                {/* Arrow */}
                <div className="transition duration-500 group-focus:-rotate-180 ease group-focus:text-platyBlue">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    d="M1 1l8 8 8-8"
                  />
                </svg>
                </div>
              </div>
              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">

                <p className="p-4 pb-8 max-w-3xl text-platyGrayText font-extralight">
                The simple answer is yes; Platypus is right for your organization no matter where you are on your health equity journey. We support organizations across this journey, from basic understanding of health disparities to designing and developing equitable AI systems. Organizations should partner with Platypus because of our unique blend of passion, lived experience, and technical expertise in healthcare data science and AI platforms aimed at addressing health disparities.
                 </p>
              

              </div>
            </div>

            {/* Tab 4 */}
            <div className="py-1 border-b outline-none group" tabIndex="4">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-600 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="text-xl transition duration-500 group-hover:text-platyBlue">
                Why and how did you build your Health Equity Network?
                </div>

                {/* Arrow */}
                <div className="transition duration-500 group-focus:-rotate-180 ease group-focus:text-platyBlue">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>
              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">

                <p className="p-4 pb-8 max-w-3xl text-platyGrayText font-extralight">
                Platypus believes that “health equity is an ecosystem problem that requires an ecosystem solution.” Therefore, we designed our Health Equity Network to connect organizations with experts regardless of their stage in this complex journey. We vet individuals through an interview process, bringing only the top thought leaders and solutions to our platform. If you’re interested in joining this network, connect with us.
                </p>

              </div>
            </div>

            

            {/* Tab 5 */}
            <div className="py-1 border-b outline-none group" tabIndex="5">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-600 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="text-xl transition duration-500 group-hover:text-platyBlue">
                Does Platypus have a larger vision for the health equity space?
                </div>

                {/* Arrow */}
                <div className="transition duration-500 group-focus:-rotate-180 ease group-focus:text-platyBlue">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>
              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">

                <p className="p-4 pb-8 max-w-3xl text-platyGrayText font-extralight">
                How did you know? Our vision is to evolve alongside healthcare organizations as we work to create a more equitable healthcare system. Stay tuned to see our ideas and progress!
                </p>

              </div>
            </div>


          </div>         
        </div>
      </section>
  )
}

export default Accordian