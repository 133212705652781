import { useState, useEffect, useRef } from "react";
import { Link, NavLink } from 'react-router-dom'

import AnnouncementBar from "./AnnouncementBar";

import platypusLogo from '../../assets/images/platypus-logo.svg'

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const ref =useRef()

  useEffect(() =>{
    const handler = (e) => {
      if (
        isNavOpen &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsNavOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [isNavOpen, setIsNavOpen])


  return (
    <>

      <AnnouncementBar />
      {/* Nav Container */}
      <nav ref={ref} className="sticky top-0 bg-white bg-opacity-95 shadow-lg">
        <div className="container p-4 md:py-4 lg:px-0 mx-auto">
          {/* Flex Container for All Items */}
          <div className="flex items-center justify-between">
            {/* Flex Container For Logo/Menu */}
            <div className="flex items-center space-x-40">
              {/* Logo */}
              <Link to='/'>
                <img 
                  src={ platypusLogo }
                  title="Platypus" 
                  alt="Platypus Health company logo"
                  height="63"
                  width="180"
                  loading="eager" 
                />
                  
              </Link>
              
            </div>

            {/* Right Menu */}
            <div className="items-center hidden space-x-6 lg:flex text-black">
              <NavLink to='/'>Home</NavLink>
              <NavLink to='/frequently-asked-questions'>FAQ</NavLink>
              <NavLink to='/articles'>Articles</NavLink>
              <Link to='/connect-with-us' className="px-8 p-1 font-semibold text-black hover:text-white rounded bg-platyGold shadow-md hover:bg-platyBlue">Connect</Link>    
            </div>

            {/* Hamburger Menu */}
            <button 
              id="menu-btn" 
              type="button" 
              onClick={() => setIsNavOpen((prev) => !prev)}
              className={isNavOpen ? "open z-40 block hamburger lg:hidden focus:outline-none" : "z-40 block hamburger lg:hidden focus:outline-none"}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </button>
                
          </div>

          {/* Mobile Menu */}
          <div 
            id="menu" 
            className={isNavOpen ? "absolute p-12 rounded-sm bg-platyViolet left-0 right-0 top-24 z-100 flex" : "hidden"}
          >
            <div className="flex flex-col items-center justify-center w-full space-y-6 text-white ">
                <Link onClick={() => setIsNavOpen(false)} to="/" className="w-full text-center">Home</Link>
                <Link onClick={() => setIsNavOpen(false)} to="/frequently-asked-questions" className="w-full text-center">FAQ</Link>
                <Link onClick={() => setIsNavOpen(false)} to="/articles" className="w-full text-center">Articles</Link>
                <Link onClick={() => setIsNavOpen(false)} to="/connect-with-us" className="w-full py-2 text-center font-semibold text-black rounded bg-platyGold">Connect</Link>
            </div>
          </div>

        </div>
        
        
      </nav>
    </>

  )
}

export default Header