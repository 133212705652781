//Utilities
import React from 'react'
import { Link } from 'react-router-dom'

//Images
import launchImage from '../../assets/images/eda-provider-meeting.jpg'

function CTA() {
  return (
    <>
      {/*Partner CTA */}
      <section id="about" className="bg-white py-16">
        {/*CTA Container */}
        <div className="container flex flex-col-reverse items-center px-6 lg:py-16 mx-auto lg:flex-row lg:space-x-16">
          {/* CTA Content */}
          <div className="flex flex-col items-start lg:w-1/2 p-4">
            <div className="flex flex-col space-y-5">
              <h2 className="text-4xl font-bold">Platypus Health is at the forefront of managing bias in AI for a diverse set of use cases.</h2>
              
              <div className='space-y-5'>
                <div>
                  <h3 className='font-bold'>Clinical Decision Support Systems:</h3>
                  <p className='py-2 font-light'>By remediating bias in algorithms that provide evidence-based recommendations, clinicians can receive more accurate and fair guidance in diagnosis, treatment selection, and identifying potential drug interactions. This leads to improved patient outcomes, increased trust in AI systems, and better clinical efficiency.
</p>
                </div>

                <div>
                  <h3 className='font-bold'>Population Health Management:</h3>
                  <p className='py-2 font-light'>Limiting bias from machine learning systems that stratify at-risk populations ensures that all demographic groups are equally considered. This leads to more accurate predictions of disease outbreaks and more effective public health interventions, ultimately optimizing resource allocation and success in value-based care initiatives.</p>
                </div>

                <div>
                  <h3 className='font-bold'>Operational Efficiency Tools:</h3>
                  <p className='py-2 font-light'>Working to remove bias from algorithms that optimize hospital operations, healthcare facilities can more accurately predict patient admission rates, manage staff schedules, and improve supply chain management. This leads to reduced operational costs, enhanced service delivery, and better resource utilization across the board.</p>
                </div>

                <div>
                  <h3 className='font-bold'>Fraud Detection and Preventions:</h3>
                  <p className='py-2 font-light'>Curbing inequities in algorithms detecting unusual patterns in billing and claims data ensures that all potential fraud cases are identified without discrimination. This enhances financial integrity, ensures compliance with regulations, and promotes fair and accurate fraud prevention efforts.</p>
                </div>

              </div>

              <p className="font-extralight  text-xl text-gray-900">Don't see your use case listed?</p>
            </div>

            {/* Button */}
            <div className="py-12 mx-auto md:m-0">
              <Link to="/connect-with-us" className="p-4 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-platyGray">Connect with us</Link>
            </div>
          </div>

          {/* CTA Image */}
          <div className="lg:w-1/2 p-4 mb-8">
            <img 
              src={launchImage}
              title="Team collaboration with Platypus"
              alt="Team standing together"
              height="500"
              width="750"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default CTA