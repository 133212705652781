import React, {useState} from 'react'
import base from '../../api/base'
import { useForm } from "react-hook-form"
//import { DevTool } from '@hookform/devtools'
import { FaCheckCircle } from 'react-icons/fa'

function ContactForm() {
  const form = useForm()
  const { register, handleSubmit, formState } = form
  const { errors } = formState
  const [status, setStatus] = useState(false)

  const onSubmit = (e) => {
    
    const first_name = e.firstName
    const last_name = e.lastName
    const email = e.email
    const org_name = e.organization
    const org_type = e.organizationType
    const message = e.messageBody

    base('Website Connect Form 2024').create(
      { 
        first_name, 
        last_name, 
        email, 
        org_name, 
        org_type, 
        message },
      function (err, record) {
        if (err) {
          alert('We\'re. Sorry something went wrong.')
          return;
        }
        setStatus(true)
      }
    );

    console.log(e)
  };

  
  
  return (
    <>
      { status ? (
        <div className='mx-auto text-center space-y-6'>
          <FaCheckCircle className='text-green-700 text-5xl mx-auto' />
          <p>Thank you for your submission.</p>
        </div>
      ):(

      <form action="submit" onSubmit={handleSubmit(onSubmit)} noValidate>

        {/* First Name */}
        <div className='mb-3'>
          <label htmlFor='firstName' className='block mt-3'>
            <span className='text-gray-700'>First Name:</span>
          </label>
          <input 
            id="firstName"
            type="text" 
            autoComplete='given-name'
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder=""
            {...register("firstName", 
              {required: 'This field is required',
              maxLength: 80
            })} 
          />
          <p className="text-red-700">{errors.firstName?.message}</p>
        </div>
        
        {/* Last Name */}
        <div className='mb-3'>
          <label htmlFor='lastName' className='block mt-3'>
            <span className='text-gray-700'>Last Name:</span>
          </label>
          <input
            id="lastName" 
            type="text" 
            autoComplete='family-name'
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            placeholder=""
            {...register("lastName", 
              {required: 'This field is required',
              maxLength: 100
            })} 
          />
          <p className="text-red-700">{errors.lastName?.message}</p>
        </div>
        
        
        {/* Email */}
        <div className='mb-3'>
          <label htmlFor='email' className='block mt-3'>
            <span className='text-gray-700'>Email:</span>
          </label>
          <input 
            id="email"
            type="email" 
            autoComplete='false'
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            placeholder="john@example.com"
            {...register("email", {
              required: 'Please enter valid email address',
              pattern: {value: /^\S+@\S+$/i, message: 'Invalid email format'}
            })} 
          />
          <p className="text-red-700">{errors.email?.message}</p>
        </div>
        
        
        {/* Organization */}
        <div className='mb-3'>
          <label htmlFor='organization' className='block mt-3'>
            <span className='text-gray-700'>Organization:</span>
          </label>
          <input 
            id="organization"
            type="text" 
            autoComplete='false'
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
            placeholder=""
            {...register("organization", 
              {
              maxLength: {value: 100, message: 'must be less than 100 characters'}
            })}  
          />
          <p className="text-red-700">{errors.organization?.message}</p>
        </div>
        
        
        {/* Organization Type */}
        <div className='mb-3'>
          <label htmlFor='organizationType' className='block mt-3'>
            <span className='text-gray-700'>I am a:</span>
          </label>
          <select 
            id='organizationType'
            autoComplete='false' 
            defaultValue={"option"}
            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            {...register("organizationType")}
          >
            <option disabled value={"option"}> -- select an option -- </option>
            <option value={"Hospital Organization"}>Hospital Organization</option>
            <option value={"Healthcare Payer"}>Healthcare Payer</option>
            <option value={"Pharmacy Organization"}>Pharmacy Organization</option>
            <option value={"Healthcare Consultancy"}>Healthcare Consultancy</option>
            <option value={"Government Agency"}>Government Agency</option>
            <option value={"Non-profit"}>Non-profit</option>
            <option value={"Other"}>Other</option>
          </select>
        </div>
        
        {/* Message */}
        <div className='mb-3'>
          <label htmlFor='messageBody' className='block mt-3'>
            <span className='text-gray-700'>Message:</span>
          </label>
          <textarea 
            id="messageBody" 
            autoComplete='false'
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"   
            rows="6"
            {...register("messageBody", 
              {
              maxLength: 250
            })}
          >

          </textarea>
          <p className="text-red-700">{errors.messageBody?.message}</p>
        </div>
        


        <div className="text-center md:text-left w-full pt-4 mt-6 space-x-4">
          <button className="p-4 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-white">Connect with us</button>
        </div>

      </form>
    )}

    </>
    
  )
}

export default ContactForm