// Utilities
import React from 'react'
import { Link } from 'react-router-dom'


// Images and Icons
import platypusLogo from '../../assets/images/platypus-logo-white.svg'
import { FaLinkedin, FaEnvelope, FaXTwitter } from 'react-icons/fa6'

function Footer() {
  const footerYear = new Date().getFullYear()
  return (
    <>
      <footer className="pt-16 pb-8 bg-platyBlue">
        <div className="container p-6 lg:px-0 flex flex-col items-center justify-between mx-auto space-y-8 md:flex-row md:space-y-0 md:items-start">
            {/* Logo */}
            <img 
              src={platypusLogo}
              title="Platypus" 
              alt="Platypus Health company logo in the footer" 
              height="63"
              width="180"
              loading="eager" 
            />

           
            {/* Social Container */}
            <div className="flex text-sm space-x-6">
                <Link to='/privacy-policy' className="text-white">Privacy</Link>
                <Link to='https://www.linkedin.com/company/platypushealth' target='_blank'>
                  <div className="py-1">
                    <FaLinkedin aria-label="Platypus LinkedIn Page" className='text-white'/>
                  </div>
                </Link>
                
                <Link to='https://twitter.com/platypus_health' target='_blank'>
                  <div className="py-1">
                    <FaXTwitter aria-label="Platypus Twitter Account" className='text-white'/>
                  </div>
                </Link>

                <Link to='mailto:support@platypus.health' target='_blank'>
                  <div className="py-1">
                    <FaEnvelope aria-label="Email Us" className='text-white'/>
                  </div>
                </Link>
            </div>
        </div>
        <div className="text-center text-white text-xs pt-8">
            <div>&copy;{footerYear} Platypus Health. All rights reserved.</div>
            <div>Platypus was born out of the <Link className='underline' target="_blank" to='http://www.hashedhealth.com'>Hashed Health</Link> venture studio.</div>
            
        </div>


    </footer>

    </>
  )
}

export default Footer