import React from 'react'
import { Link } from 'react-router-dom'
import { FaRocket, FaCompass} from 'react-icons/fa'

function LaunchCTA() {
  return (
    <section id="features" className="py-16 bg-platyGray">
      <div className="container flex flex-col px-6 pt-16 pb-16 mx-auto md:flex-row md:space-x-16">
        <div className="lg:w-1/2 lg:p-4">
          <h2 className='mb-6 text-4xl font-bold'>Ready to get started?</h2>
          <p>Join a group of healthcare data leaders empowering their organizations with insights to make better decisions and improve outcomes. Platypus is here to analytically support healthcare organizations as the landscape begins to focus more on health equity in growth, financial, operational, and quality functions.</p>

          <div className="w-full pt-10 space-x-4">
              <Link to='/connect-with-us' className="p-4 text-center text-sm font-semibold text-black bg-platyGold rounded shadow-md md:text-base hover:bg-platyBlue hover:text-platyGray">Connect with us</Link>
          </div>
        </div>
        <div className="lg:w-1/4 mt-8 lg:p-4">
          <FaRocket className='text-platyViolet text-8xl py-4'/>
          <h3 className='mb-4 text-md font-medium'>Drive efficiency</h3>
          <p className='text-sm'>Leverage a platform that uniquely provides the ability to discover biases, implement solutions, and create equity in improving outcomes and reducing costs.</p>
        </div>
        <div className="lg:w-1/4 mt-8 lg:p-4">
          <FaCompass className='text-platyViolet text-8xl py-4'/>
          <h3 className='mb-4 text-md font-medium'>Create value</h3>
          <p className='text-sm'>Jumpstart partnerships and progress in generating more value in your organization while expanding the scope of analysis to reduce bias and improve applicability to diverse populations.</p>
        </div>
      </div>
    </section>
  )
}

export default LaunchCTA