import React from 'react'
import { useState } from "react";

import TabNavItem from './TabNavItem';
import TabContent from './TabContent';

import valueBasedCare from '../../../assets/images/value-based-care.jpg'
import dataSci from '../../../assets/images/data-sci-team.jpg'
import busTeam from '../../../assets/images/business-team.jpg'

function Tabs({ children }) {
  const [activeTab, setActiveTab] = useState("tab1")

  return (
    <>
      {/* Features Tabs  */}
      <section id="tabs">
        {/* Tabs/Panels Container */}
        <div className="container py-16 mx-auto my-6 mt-16 mb-16">
          <div className="container p-6 mx-auto">
            {/* <p className='text-center mb-4 text-sm text-gray-600 uppercase'>Solutions</p> */}
            <h2 className="mb-6 text-4xl font-bold text-center ">Solutions</h2>
            <p className="max-w-md mx-auto text-center text-2xl md:max-w-2xl">Platypus Health is at the forefront of solving health equity issues for a diverse set of use cases. </p>
          </div>


          <div className="bg-tabs">
          {/* Tabs Nav Container */}
          <div className="flex flex-col justify-center max-w-xl mx-auto mb-6 border-b md:space-x-10 md:flex-row">
            <TabNavItem title="Quality & Population Health" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} />
            <TabNavItem title="Value-Based Care Organizations" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />
            <TabNavItem title="Data & Analytics Teams" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          </div>

          {/* Tab Panels */}
          <div id="panels" className="container mx-auto">

            {/* Panel 1 */}
            <TabContent id="tab1" activeTab={activeTab}>
              <div className="flex flex-col items-center py-5 md:flex-row md:space-x-7 panel panel-1">

                {/* Panel Image */}
                <div className="flex justify-center md:w-1/2 p-8">
                  <img 
                    src={busTeam} 
                    title="Healthcare team looking to improve risk identification"
                    alt="Team standing together" 
                    height="541"
                    width="750"
                    loading="lazy"
                    />
                </div>

                {/* Panel Content */}
                <div className="flex flex-col space-y-8 p-8 md:w-1/2">
                  <h3 className="mt-32 text-3xl font-semibold text-center md:mt-0 md:text-left">
                  Improve Proactive Risk Identification
                  </h3>
                  
                  <ul className='list-disc space-y-3 mx-4'>
                    <li>Understand data bias before developing risk stratification processes.</li>
                    <li>Augment health equity strategy to include evaluation for Medicaid Core Set.</li>
                    <li>Assist in imputation for HEDIS and STARs reporting.</li>
                  </ul>    
                </div>
              </div>
            </TabContent>

            {/* Panel 2 */}
            <TabContent id="tab2" activeTab={activeTab}>
              <div className="flex flex-col items-center py-5 md:flex-row md:space-x-7 panel panel-1">

                {/* Panel Image */}
                <div className="flex justify-center md:w-1/2 p-8">
                  <img 
                    src={valueBasedCare}
                    title="Improving vlaue based care organization" 
                    alt="Nurse and a patient checking blood pressure"
                    height="541"
                    weight="750"
                    loading="lazy" 
                  />
                </div>

                {/* Panel Content */}
                <div className="flex flex-col space-y-8 p-8 md:w-1/2">
                  <h3 className="mt-32 text-3xl font-semibold text-center md:mt-0 md:text-left">
                  Meeting Health Equity Reporting Requirements
                  </h3>
                  <ul className='list-disc space-y-3 mx-4'>
                    <li>Understand where data bias exists.</li>
                    <li>Receive routine insight disparities that are impacting program performance.</li>
                    <li>Access to health equity experts to meet Health Equity Plan requirements.</li>
                  </ul>       
                </div>
              </div>
            </TabContent>

            {/* Panel 3 */}
            <TabContent id="tab3" activeTab={activeTab}> 
              <div className="flex flex-col items-center py-5 md:flex-row md:space-x-7 panel panel-3" >

                {/* Panel Image */}
                <div className="flex justify-center md:w-1/2 p-8">
                  <img 
                    src={dataSci} 
                    title="Improve Foundation for Reporting and ML/AI Training"
                    alt="Data scientist working at his desk"
                    height="540"
                    width="750"
                    loading="lazy"
                  />
                </div>

                {/* Panel Content */}
                <div className="flex flex-col space-y-8 p-8 md:w-1/2">
                  <h3 className="text-3xl font-semibold text-center mt-14 md:mt-0 md:text-left">
                  Improve Foundation for Reporting and ML/AI Training
                  </h3>
                  <ul className='list-disc space-y-3 mx-4'>
                    <li>Understand data bias before training ML/AI models.</li>
                    <li>Access transparency report to build trust in your data and analytical products.</li>
                    <li>Meet expectations of properly building Health Equity Impact Assessments.</li>
                  </ul>      
                  
                </div>
              </div>
            </TabContent>

          </div>
        </div>
      </section>

    </>
  )
}

export default Tabs