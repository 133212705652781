// Utilities
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Images

//Componenets
import Form from './widgets/ContactForm'
import DownloadWhitePaperCTA from './widgets/DownloadWhitePaperCTA'


function ConnectPage() {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Platypus Health | Connect with us</title>
          <meta name="description" content="Connect with Platypus Health." />
          <link rel="canonical" href="https://www.platypus.health/connect-with-us" />
        </Helmet>
      </HelmetProvider>


      {/*Connect */}
      <section id="about" className="bg-platyGray py-16">
        {/*Connect Container */}
        <div className="container flex flex-col px-6 mx-auto md:flex-row md:space-x-16">
            {/* Content */}
            <div className="lg:w-1/2 p-4 space-y-5">
              {/* Text Section */}
              <h1 className=" text-3xl font-semibold mx-auto text-platyGrayText md:text-4xl">Reach out and connect with us</h1>
              
              <p className="font-extralight max-w-md text-xl text-gray-900 md:max-w-lg">We are now accepting applications for our private beta. Complete the form to find out what Platypus can do to support your journey to equitable healthcare.</p> <p className='text-lg text-gray-900'>Our private beta offers:</p>

              <ul className="font-extralight space-y-3 list-disc mx-4">
                <li>Early-adopter pricing.</li>
                <li>Early access to product features.</li>
                <li>Increased sharing incentives.</li>
              </ul>
            </div>

            {/* Form */}
            <div className="flex flex-col lg:w-1/2 p-4">
              <div className="flex flex-col space-y-5">

                <Form />

              </div>               
            </div>
        </div>
      </section>

      <DownloadWhitePaperCTA />
  </>
  )
}

export default ConnectPage