//Utilities
import React from 'react'
import { Link } from 'react-router-dom'

//Images and Icons
import LinkIcon from '../../assets/icons/LinkIcon.svg'
import Teamwork from '../../assets/images/value-of-teamwork.jpg'

function ValueProp() {
  return (
    <>
      {/*Value Prop */}
      <section id="about" className="bg-white py-16">
          {/*Productive Container */}
          <div className="container flex flex-col items-center px-6 pt-16 pb-16 mx-auto lg:flex-row lg:space-x-16">
              {/* Image */}
              <div className="lg:w-1/2 p-2 mb-8">
                  <img 
                    src={Teamwork}
                    title = "Solving the problem."
                    alt="Team working together."
                    height="500"
                    width="812"
                    loading="eager"
                  />
              </div>
              {/* Content */}
              <div className="flex flex-col items-start lg:w-1/2 p-4">
                  <div className="flex flex-col space-y-5">
                      <h2 className="text-4xl font-bold mb-0">The costs of bias in AI are mounting.</h2>
                      <p className="font-extralight max-w-md text-lg text-gray-900 md:max-w-lg">Bias occurs when inequities in data, development, or deployment lead to systematic errors, reducing the overall accuracy and reliability of AI systems.
                      </p>

                      <ul className="font-extralight list-disc space-y-3 mx-4" >
                        <li><b>Liability and Operational Concerns:</b> Misdiagnoses or biased resource allocation by AI systems can lead to malpractice claims and inefficient use of resources, raising operational costs for healthcare facilities.</li>

                        <li><b>Increased Clinical Costs:</b> Biased AI systems can lead to unnecessary or incorrect treatments, increasing clinical costs and reducing the overall efficiency of healthcare delivery.</li>

                        <li><b>Workforce Dissatisfaction and Turnover:</b> AI systems that unfairly manage workforce schedules can result in higher recruitment and training costs due to turnover.</li>  

                        <li><b>Contractual and Data Protection Violations:</b> Biased AI can lead to breaches of contractual terms and data protection laws like HIPAA, resulting in significant fines and legal disputes with AI vendors.</li>                       
                      </ul>
                  </div>

                  {/* Link */}
                  <div className="block mt-4 underline md:no-underline md:hover:underline">
                      <Link 
                        to="/articles" 
                        className="font-semibold text-lg">
                          Understand more about bias in AI<img 
                                                            src={LinkIcon} 
                                                            alt="arrow button" 
                                                            title="arrow button" 
                                                            height="25" 
                                                            width="28" 
                                                            className="inline ml-2 pb-2"
                                                            loading="lazy" 
                                                          />
                      </Link>
                  </div>
              </div>

          </div>
      </section>
    </>
  )
}

export default ValueProp