// Utilities
import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ReactGA from "react-ga4";

//Components
import Header from './components/shared/Header'
import Footer from './components/shared/Footer'
import ScrollToTop from './components/shared/ScrollToTop'

import Home from './pages/Homepage'
import FAQ from './pages/FaqPage'
import Connect from './pages/ConnectPage'
import NotFound from './pages/NotFound'

import Privacy from './pages/PrivacyPage'

import ArticleList from './pages/blog/Posts'
import ArticleDetails from './pages/blog/SinglePost'

import DownloadWhitepaper from './pages/WhitePaperDownload'

const TRACKING_ID = "G-1P9T29G02R"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <Router>
        <Header />

        <main>
          <ScrollToTop />
          <Routes>
            {/* Main Nav */}
            <Route path='/' element={<Home />} />
            <Route path='/frequently-asked-questions' element={<FAQ />} />
            <Route path='/connect-with-us' element={<Connect />} />

            {/* Blog */}
            <Route path='/articles' element={<ArticleList />} />
            <Route path='/article/:vanityUrl' element={<ArticleDetails />} />

            {/* Footer Nav */}
            <Route path='/privacy-policy' element={<Privacy />} />

            {/* Utility Pages */}
            <Route path='/white-paper' element={<DownloadWhitepaper />} />
            <Route path='/*' element={<NotFound />} />
          </Routes>
        </main>

        <Footer />
      </Router>
      
    </>
    
  )
}

export default App