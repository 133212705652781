//Utilities
import React from 'react'

//Images and Icons
import { FaBinoculars, FaChartLine, FaCode } from 'react-icons/fa'

function Features() {
  return (
    <>
      {/* Features */}
      <section id="features" className="py-16 bg-white-txt-bg bg-cover">
          <div className="container p-6 mx-auto">
              <p className='text-center mb-4 text-sm text-gray-600 uppercase'>Welcome to</p>
              <h2 className="mb-6 text-4xl font-bold text-center underline decoration-platyGold">Platypus Health</h2>
              <p className="max-w-md mx-auto text-center text-2xl md:max-w-2xl">Tools that drive more value for your AI investments.</p>
          </div>

          <div className="container mt-10 mx-auto">
              <div className="grid lg:grid-cols-3 text-center">
                  <div className="p-8">
                      <FaBinoculars className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-2xl font-bold mt-6 mb-6'>Identify Bias & Risks</h3>
                      <p className='font-extralight text-left text-lg text-light text-gray-900'>Comprehensive evaluation for surfacing fairness and bias issues. Drive value through machine learning explainability and assessments of business value alignment.</p>
                  </div>
                  <div className="p-8">
                      <FaCode className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-2xl font-bold mt-6 mb-6'>Remediate Bias</h3>
                      <p className='font-extralight text-left text-lg text-light text-gray-900'>Access expert guidance and custom recommendations to improve your risk mitigation strategy while building a history of progress through reporting.</p>
                  </div>
                  <div className="p-8">
                      <FaChartLine className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-2xl font-bold mt-6 mb-6'>Continuously Monitor</h3>
                      <p className='font-extralight text-left text-lg text-light text-gray-900'>Increase speed to value by improving AI accuracy and applicability. Carry forward historical learnings while building a roadmap for your organization's future.</p>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default Features