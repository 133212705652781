//Utilities 
import React from 'react'
import { Link } from 'react-router-dom'

export default function DownloadWhitePaperCTA() {
  return (
    <>
      {/* Whitepaper Section */}
      <section className="bg-white-paper bg-cover bg-bottom bg-no-repeat">
      <div className="container max-w-3xl py-24 mx-auto">
          <p className="mb-6 text-lg tracking-widest text-center text-white uppercase">Download Our Whitepaper</p>
          <h2 className="px-3 mb-6 text-3xl font-semibold text-center text-white md:text-4xl">Accelerate Equity: The Time-Critical Data Imperative for Healthcare</h2>
          <p className="mb-6 text-lg font-light text-center text-white">In a world defined by groundbreaking healthcare technology and data-driven decision-making, achieving health equity is not just a goal - it’s a pressing imperative. </p>
          <p className="mb-6 text-lg font-light text-center text-white">Our new, free white paper, “Accelerate Equity: The Time-Critical Data Imperative for Healthcare,” dives deep into the challenges and opportunities presented and how to be a leader in creating equal access to quality care.
          </p>

          
          <div className="text-center w-full pt-4 space-x-4">
              <Link to='/white-paper' className="p-4 text-center text-sm font-semibold text-black bg-platyGray border-gray-300 rounded shadow-md md:text-base hover:bg-platyGold hover:text-platGrayText">Download It Now</Link>
          </div>

      </div>
    </section>
    </>
  )
}
