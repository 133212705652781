//Utilities
import React from 'react'
import { Link } from 'react-router-dom'

//Images
import HeroImg from '../../assets/images/hero-mobile.jpg'

function Hero() {
  return (
    <section className='bg-none md:bg-hero bg-cover bg-no-repeat bg-top'>
      <div className='bg-none border-b md:bg-gradient-to-r md:from-platyBlue to-transparent'>
      {/* Image */}
      <div className="mx-auto lg:mb-24 md:hidden ">
          <img 
            src= { HeroImg } 
            title="Welcome to Platypus health"
            alt="Data science team working together"
            height="294"
            width="640"
            loading="eager"
          />
      </div>
      {/* Hero Container */}
      <div className="container  flex flex-col-reverse p-6 mx-auto lg:flex-row">
          {/* Content Container */}
          <div className="flex flex-col space-y-8 mb-32 lg:mt-32 md:w-2/3 lg:w-1/2">
              {/* Headline */}
              <h1 className="md:text-white text-5xl font-bold text-center lg:text-5xl leading-tight lg:leading-snug md:text-left">
              Enabling more effective AI for Healthcare.
              </h1>
              {/* Subheadline */}
              <p className="text-2xl text-center md:text-white md:max-w-3xl md:text-left">
              Optimize your AI investments by identifying, remediating, and monitoring bias in your AI.  
              </p>
              {/* Buttons */}
              <div className="flex flex-col md:flex-row items-center justify-center w-full space-y-3 md:space-y-0 md:space-x-4 md:justify-start">
                <Link to="/connect-with-us" className="p-4 text-center text-sm font-semibold text-white hover:text-black bg-platyViolet border-gray-300 rounded shadow-md md:text-base hover:bg-platyGold hover:text-platGrayText">Connect with us</Link>
                <Link to="/white-paper" className="p-4 text-center text-sm font-semibold md:text-black bg-platyBlue text-white md:bg-platyGray border-gray-300 rounded shadow-md md:text-base hover:bg-platyGold hover:text-platGrayText">Download white paper</Link>
              </div>
          </div>
          
      </div>
      </div>
    </section>
  )
}

export default Hero