import React from 'react'

// Images and Icons
import FAQImage from '../../assets/images/platypus-screenshot.png'
import { FaShop, FaPeopleLine, FaLock, FaScaleBalanced } from 'react-icons/fa6'

function FeatureBlock1() {
  return (
    <section id="faq-feature" className="bg-platyGray">
        <div className="container flex flex-col lg:items-center pt-16 pb-16 mx-auto lg:flex-row md:space-x-16">

          {/* Feature Image */}
          <div className="lg:w-1/2 p-2">
            <img 
              src={FAQImage} 
              title="Platypus health platform"
              alt="computer with screenshot of Platypus" 
              height="559"
              width="750"
              loading="eager"
            />
          </div>

          {/* Content */}
          <div className="flex flex-col items-start lg:w-1/2 p-4">

            <p className="px-3 mb-4 text-sm tracking-widest text-platyGrayText uppercase">About</p>
            <h2 className="px-3 mb-6 text-3xl font-semibold text-center text-platyGrayText md:text-5xl">Platypus Health</h2>

            {/* Feature Items */}
            <div className="flex flex-col md:flex-row flex-wrap">

              {/* Item 1 */}
              <div className="lg:w-1/2 p-3">
                <FaLock className='text-platyGold text-7xl py-4'/>
                <h3 className='text-2xl font-bold mt-2 mb-2'>Currated Collaboration</h3>
                <p className='font-light'>Collaborate on and consume equitable AI without exposing data or intellectual property.</p>
              </div>

              {/* Item 2 */}
              <div className="lg:w-1/2 p-3">
                <FaPeopleLine className='text-platyGold text-7xl py-4'/>
                <h3 className='text-2xl font-bold mt-2 mb-2'>Product Commercialization</h3>
                <p className='font-light'>Avoid lengthy validation and contract processes, streamlining accessibility and adoption of equitable AI.</p>
              </div>

              {/* Item 3 */}
              <div className="lg:w-1/2 p-3">
                <FaShop className='text-platyGold text-7xl py-4'/>
                <h3 className='text-2xl font-bold mt-2 mb-2'>Health Equity Marketplace</h3>
                <p className='font-light'>Leverage a network of health equity experts to augment your efforts as needed.</p>
              </div>

              {/* Item 4 */}
              <div className="lg:w-1/2 p-3">
                <FaScaleBalanced className='text-platyGold text-7xl py-4'/>
                <h3 className='text-2xl font-bold mt-2 mb-2'>Equity Aligned Approach</h3>
                <p className='font-light'>Focus on engaging healthcare organizations that serve diverse populations.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
  )
}

export default FeatureBlock1