//Utilities
import React from 'react'

//Images and Icons
import Leigh from '../../assets/images/leigh.jpg'

function ExecutiveQuote() {
  return (
    <>
      {/* Executive Quote */}
      <section id="features" className="bg-white-txt-bg bg-cover py-16">
        <div className="container flex flex-col-reverse items-center lg:flex-row m-10 p-10 mx-auto bg-platyDarkGray justify-center rounded-lg">

          {/* Image */}
          <div className="lg:w-1/4 p-4">
            <img 
              className='mx-auto' 
              src={Leigh}
              title="Leigh McCormack"
              alt="Leigh McCormack founder of Platypus Health"
              height="199"
              width="199"
              loading="lazy"
            />
          </div>

          {/* Quote */}
          <div className="lg:w-3/4 p-10">
            <div className="text-2xl font-light">“Healthcare organizations, policymakers, and innovators have the opportunity to address AI bias proactively. Together, we can leverage technology that enhances our experience with AI, improving outcomes and creating a more effective healthcare system.”</div>
            
            <div className="mt-4">
              <div className="text-sm font-semibold">Leigh McCormack</div>
              <div className="text-xs">CEO, Founder Platypus</div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default ExecutiveQuote