// Utilities
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Images and Icons
import { FaHeartBroken } from 'react-icons/fa'


function NotFound () {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Platypus Health | 404 Not Found</title>
        </Helmet>
      </HelmetProvider>


    {/*404 Not Found Page */}
    <section id="about" className="bg-platyGray py-24">
      {/* Container */}
      <div className="container text-gray-900 flex flex-col px-6 text-center  mx-auto space-y-6">
        {/* Content */}         
        <div className="mx-auto max-w-2xl">
          <FaHeartBroken className='text-6xl text-platyViolet'/>
        </div>     

        <h1 className='text-6xl mb-8 font-bold'>404: Not Found</h1>
        
        <p className="text-2xl mb-8">The page you are looking for doesn't seem to exist. Let's go back <Link className='text-platyViolet underline' to='/'>Home</Link></p>

       </div>

      

    </section>
  </>
  )
}

export default NotFound