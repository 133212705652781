import React from 'react'
import { useState} from "react";
import { Link } from 'react-router-dom'
import { FaX } from 'react-icons/fa6'

function AnnouncementBar() {
  const [isHidden, setIsHidden] = useState(false)

  return (
    <>
      <div className={isHidden ?  "hidden bg-platyBlue text-white" : "bg-platyBlue text-white"}>
        <div className="container mx-auto">
          <div className="flex flex-row py-1 items-center">
            <div className="mx-auto">
              <p className="max-w-sm text-center md:max-w-full">
                <span className="rounded-sm font-semibold bg-platyGray px-1 mx-2 text-platyBlue">Coming Soon:</span>Platypus private beta. <Link to='/connect-with-us' className='underline'>Connect</Link> to learn more.
              </p>
            </div>
            <div className="mr-3 md:mr-0">
              <button
                id='close-btn' 
                type="button" 
                onClick={() => setIsHidden((prev) => !prev)}
                className="m-2 flex rounded-md focus:outline-none focus:ring-2 ">
                <FaX className='white'/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnnouncementBar